<template>
  <b-card>
    <div slot="header">
      <strong>Editar Regra</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Regras' }">Listar Regras</router-link></p>
    <b-alert variant="danger" show>Note que a criação de regras dependem de implementação interna do sistema.<br>
    Utilize esta área apenas se tem certeza do que está fazendo!</b-alert>
    
    <form v-on:submit.prevent="salvaRegra">
      <div class="row">

        <div class="col-lg-10">
          <div class="form-group">
              <label name="descricao"><strong>Descrição</strong></label>
              <input type="text" class="form-control" v-model="regra.role_descricao" id="descricao" required>
          </div>
        </div>

        <div class="col-lg-2">
          <div class="form-group">
              <label name="descricao"><strong>Ativo</strong></label><br>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': regra.role_ativo }" 
                  @click="regra.role_ativo = true">Ativo</button>
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': !regra.role_ativo }" 
                  @click="regra.role_ativo = false">Inativo</button>
              </div>
          </div>
        </div>

      </div>
    
      <div class="form-group">
        <label name="observacao"><strong>Observação</strong></label>
        <input type="text" required class="form-control" v-model="regra.role_observacao" id="observacao">
      </div>

      <button type="submit" class="btn btn-primary">Salvar</button>
    </form>

  </b-card>
</template>

<script>
export default {
  data () {
    return {
      regra: {
        role_id: 0,
        role_descricao: '',
        role_ativo: 1,
        role_observacao: ''
      }
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.regra.role_ID = this.$route.params.id
        this.carregarRegra()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    carregarRegra: function () {
      this.campos('gp_Regra', 'role_ID = ' + this.regra.role_ID).then(
        (response) => {
          this.regra = response
        },
        (response) => {}
      )
    },
    salvaRegra: function () {
      this.atualizar('gp_Regra', this.regra, this.regra.role_ID).then(
        (response) => {
          this.$router.push('/admin/regras/listar')
        },
        (response) => {}
      )
    }
  }
}
</script>

<style>

</style>
